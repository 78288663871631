import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { generateClient } from "aws-amplify/api";
import { getParkingStallClosures } from "../../graphql/queries";
import { updateParkingStallClosures } from "../../graphql/mutations";

const client = generateClient();

const closureDetailId = "f09cf894-5e1d-42d3-ad21-e292c7e36dee";

const ParkingStallClosuresEditor = () => {
  const [closureData, setClosureData] = useState({
    title: "",
    dateRange: "",
    closureDetails: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dataLoaded = useRef(false);

  useEffect(() => {
    const fetchClosureData = async () => {
      try {
        setLoading(true);
        const response = await client.graphql({
          query: getParkingStallClosures,
          variables: {
            id: closureDetailId,
          },
        });
        const fetchedData = response.data.getParkingStallClosures;

        setClosureData(fetchedData);
        dataLoaded.current = true;
      } catch (err) {
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    if (closureDetailId) {
      fetchClosureData();
    }
  }, [closureDetailId]);

  const handleUpdate = async () => {
    console.log("Attempting to update closure data...");
    const filteredData = (({ id, title, dateRange, closureDetails }) => ({
      id,
      title,
      dateRange,
      closureDetails,
    }))(closureData);
    try {
      await client.graphql({
        query: updateParkingStallClosures,
        variables: { input: filteredData },
      });
      console.log("Closure data updated successfully:", closureData);
    } catch (err) {
      console.error("Failed to update closure data:", err);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <Typography variant="h4">Edit Parking Stall Closures</Typography>
      <TextField
        label="Title"
        fullWidth
        margin="normal"
        value={closureData.title}
        onChange={(e) =>
          setClosureData({ ...closureData, title: e.target.value })
        }
      />
      <TextField
        label="Date Range"
        fullWidth
        margin="normal"
        value={closureData.dateRange}
        onChange={(e) =>
          setClosureData({ ...closureData, dateRange: e.target.value })
        }
      />
      <Typography variant="h6">Closure Details</Typography>
      <List>
        {closureData.closureDetails.map((detail, index) => (
          <ListItem
            key={index}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              label={`Detail ${index + 1}`}
              value={detail}
              onChange={(e) => {
                const updatedDetails = closureData.closureDetails.map((d, i) =>
                  i === index ? e.target.value : d
                );
                setClosureData({
                  ...closureData,
                  closureDetails: updatedDetails,
                });
              }}
              style={{ flex: 1, marginRight: "10px" }}
            />
            <IconButton
              onClick={() =>
                setClosureData({
                  ...closureData,
                  closureDetails: closureData.closureDetails.filter(
                    (_, i) => i !== index
                  ),
                })
              }
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() =>
          setClosureData({
            ...closureData,
            closureDetails: [...closureData.closureDetails, ""],
          })
        }
        style={{ marginTop: "20px" }}
      >
        Add Detail Row
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleUpdate}
        style={{ marginTop: "20px", marginLeft: "10px" }}
        startIcon={<SaveIcon />}
      >
        Update
      </Button>
    </div>
  );
};

export default ParkingStallClosuresEditor;
